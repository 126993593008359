<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="600"
      v-if="user"
  >
    <v-card>
      <v-card-title class="headline">
        Details for {{user.username}}
      </v-card-title>
      <v-card-text>
        <v-list dense>

          <v-list-item>
            <v-list-item-content>Email:</v-list-item-content>
            <v-list-item-content class="align-end">
              <span
                  :class="{'green--text': user.verification.email_verified, 'red--text': !user.verification.email_verified}">{{
                  user.email
                }}</span>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>Phone:</v-list-item-content>
            <v-list-item-content class="align-end">
              {{ user.phone }}
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>Description:</v-list-item-content>
            <v-list-item-content class="align-end">
              {{ user.description }}
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>Birthdate:</v-list-item-content>
            <v-list-item-content class="align-end">
              {{ formatDate(user.birthdate) }}
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>Gender:</v-list-item-content>
            <v-list-item-content class="align-end">
              {{user.gender}}
              <span v-if="!user.gender">-</span>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>Informational material:</v-list-item-content>
            <v-list-item-content class="align-end">
              {{user.newsletter_consent ? 'Yes' : 'No'}}
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>Registration:</v-list-item-content>
            <v-list-item-content class="align-end">
              {{ formatDateTime(user.registration_date) }}
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>Packages:</v-list-item-content>
            <v-list-item-content class="align-end">
              {{ user.transactions.length }}
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>Avail. Circles:</v-list-item-content>
            <v-list-item-content class="align-end">
              {{ user.available_circles }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            text
            @click="$emit('close')"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState} from "vuex";
import _ from 'lodash';
import dateUtils from '../mixins/date'

export default {
  name: "UserDetails",
  mixins: [dateUtils],
  props: {
    dialog: {
      value: false
    },
    username: {
      value: null
    }
  },
  computed: {
    ...mapState(['users']),
    user() {
      const self = this
      return _.head(_.filter(this.users, (user) => {
        return user.username === self.username
      }))
    }
  }
}
</script>

<style scoped lang="scss">
  .v-list-item {
    .v-list-item__content:first-child {
      font-weight: bold;
    }
  }
</style>