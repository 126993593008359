var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v(" Circles "),_c('v-spacer'),_c('export-excel',{attrs:{"data":_vm.circlesExport,"name":"circles.xls"}},[_c('v-btn',{attrs:{"small":""}},[_vm._v("Export to Excel")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.all_circles,"search":_vm.search,"loading":_vm.loading,"items-per-page":15},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":[{text: 'Filter by commercial', value: null}, {text: 'Commercial', value: true}, {text: 'Non-commercial', value: false}],"placeholder":"Commercial"},model:{value:(_vm.commercialSearch),callback:function ($$v) {_vm.commercialSearch=$$v},expression:"commercialSearch"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":[{text: 'Filter by type', value: null}, {text: 'Public', value: 'public'}, {text: 'Private', value:'private'}],"placeholder":"Type"},model:{value:(_vm.typeSearch),callback:function ($$v) {_vm.typeSearch=$$v},expression:"typeSearch"}})],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"placeholder":"Creation Date","prepend-icon":"mdi-calendar","readonly":"","clearable":""},model:{value:(_vm.createdRangeText),callback:function ($$v) {_vm.createdRangeText=$$v},expression:"createdRangeText"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.createdSearch),callback:function ($$v) {_vm.createdSearch=$$v},expression:"createdSearch"}})],1)],1)],1)],1)]},proxy:true},{key:"item.country",fn:function(ref){
var item = ref.item;
return [(item.metadata && item.metadata.country)?_c('country-flag',{attrs:{"country":item.metadata.country}}):_vm._e()]}},{key:"item.rating",fn:function(ref){
var item = ref.item;
return [_c('v-rating',{attrs:{"value":item.rating,"background-color":"orange lighten-3","color":"orange","half-increments":"","readonly":"","dense":"","small":""}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTimestamp(item.createdAt))+" ")]}},{key:"item.reports",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.reports.length)+" ")]}},{key:"item.membersCount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.membersCount)+" ")]}},{key:"item.metadata.isCommercial",fn:function(ref){
var item = ref.item;
return [(item.metadata && item.metadata.isCommercial)?_c('strong',{staticClass:"success--text"},[_vm._v("Yes")]):_c('strong',[_vm._v("No")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.type))])]}},{key:"item.metadata.isActive",fn:function(ref){
var item = ref.item;
return [(item.metadata && item.metadata.isActive === true)?_c('strong',{staticClass:"success--text"},[_vm._v("Yes")]):_c('strong',[_vm._v("No")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_c('v-btn',{attrs:{"icon":"","color":"default"},on:{"click":function($event){return _vm.viewCircle(item)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)],1),_c('td',{staticClass:"text-center"},[(item.metadata && item.metadata.isActive)?_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deactivateCircle(item.guid)}}},[_c('v-icon',[_vm._v("mdi-account-cancel")])],1):_c('v-btn',{attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.activateCircle(item.guid)}}},[_c('v-icon',[_vm._v("mdi-account-check")])],1)],1)]}}])})],1)],1)],1),_c('confirm-dialog',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }