<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <v-avatar size="64" color="primary">
              <img v-if="selectedCircle.icon"
                  :src="selectedCircle.icon"
                  :alt="selectedCircle.name"
              >
              <span v-if="!selectedCircle.icon" class=" white--text">{{selectedCircle.name[0]}}</span>
            </v-avatar>
            <span class="ml-6">{{ selectedCircle.name }}</span>
          </v-card-title>
          <v-card-text>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Description</v-list-item-title>
                <v-list-item-subtitle>{{ selectedCircle.description }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Type</v-list-item-title>
                <v-list-item-subtitle class="text-capitalize">{{ selectedCircle.type }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Commercial</v-list-item-title>
                <v-list-item-subtitle v-if="selectedCircle.metadata && selectedCircle.metadata.isCommercial">Yes</v-list-item-subtitle>
                <v-list-item-subtitle v-else>No</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Active</v-list-item-title>
                <v-list-item-subtitle v-if="selectedCircle.metadata && selectedCircle.metadata.isActive">Yes</v-list-item-subtitle>
                <v-list-item-subtitle v-else>No</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Country</v-list-item-title>
                <v-list-item-subtitle>
                  <country-flag :country="selectedCircle.metadata.country" v-if="selectedCircle.metadata && selectedCircle.metadata.country"/>
                  <span v-if="!selectedCircle.metadata || !selectedCircle.metadata.country">-</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Rules</v-list-item-title>
                <v-list-item-subtitle>{{ selectedCircle.metadata.rules.join(', ') || '-' }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Rating</v-list-item-title>
                <v-list-item-subtitle>
                  <v-rating
                      :value="selectedCircle.rating"
                      background-color="orange lighten-3"
                      color="orange"
                      half-increments
                      readonly
                  ></v-rating>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Creator</v-list-item-title>
                <v-list-item-subtitle>
                  <div class="d-flex" v-if="owner">
                    <div class="mr-6 mt-2 text-center" @click="viewUser(owner)">
                      <v-avatar size="32">
                        <img
                            :src="owner.avatar"
                            :alt="owner.username"
                            v-if="owner.avatar"
                        >
                        <span v-if="!owner.avatar">{{owner.username[0]}}</span>
                      </v-avatar>
                      <p class="mt-2"> {{ owner.username }}</p>
                    </div>
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Members</v-list-item-title>
                <v-list-item-subtitle>
                  <div class="d-flex">
                  <div v-for="member in members" :key="member.uid" class="mr-6 mt-2 text-center"
                       @click="viewUser(member)">
                    <v-avatar size="32">
                      <img
                          :src="member.avatar"
                          :alt="member.username"
                          v-if="member.avatar"
                      >
                      <span v-if="!member.avatar">{{member.username[0]}}</span>
                    </v-avatar>
                    <p class="mt-2"> {{ member.username }}</p>
                  </div>
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Created on</v-list-item-title>
                <v-list-item-subtitle>{{ formatTimestamp(selectedCircle.createdAt) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Last message on</v-list-item-title>
                <v-list-item-subtitle>{{ formatTimestamp(selectedCircle.updatedAt) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Reports</v-list-item-title>
                <v-list-item-subtitle>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th>
                          Report
                        </th>
                        <th>
                          Username
                        </th>
                        <th>
                          Report Date
                        </th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                          v-for="report in selectedCircle.reports"
                          :key="report.id"
                      >
                        <td>{{ report.description }}</td>
                        <td>{{ report.user.username }}</td>
                        <td>{{ formatDateTime(report.report_date) }}</td>
                        <td class="text-right">
                          <v-btn v-if="!report.acknowledged" color="success" @click="acknowledgeReport(report)">Acknowledge</v-btn>
                          <v-icon v-if="report.acknowledged" color="success">mdi-check</v-icon>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <UserDetails :dialog="userDetailsDialog" :username="userDetailsUsername" @close="userDetailsDialog = false"/>
    <UserList :dialog="userListDialog" :users="userList" :circle="userListCircle" :loading="userListLoading"
              @close="userListDialog = false"/>
    <CircleReports :dialog="reportsDialog" :reports="reports" :circle="reportsCircle" @close="reportsDialog = false"/>
  </v-container>
</template>

<script>
import {mapState} from "vuex";
import dateFormat from '../mixins/date'
import CountryFlag from 'vue-country-flag'
import UserDetails from "@/components/UserDetails";
import UserList from "@/components/UserList";
import CircleReports from "@/components/CircleReports";

export default {
  name: "CircleDetails",
  mixins: [dateFormat],
  components: {
    CircleReports,
    UserList,
    UserDetails,
    CountryFlag
  },
  data: () => ({
    search: '',
    typeSearch: null,
    createdSearch: null,
    userDetailsDialog: false,
    userDetailsUsername: null,
    userListDialog: false,
    userList: [],
    userListCircle: null,
    userListLoading: false,
    reportsDialog: false,
    reports: [],
    reportsCircle: null,
    members: [],
    owner: null
  }),
  mounted() {
    this.axios.get(`circles/${this.selectedCircle.guid}/users`).then(resp => {
      this.members = resp.data
      this.owner = this.members.find(member => parseInt(member.id) === parseInt(this.selectedCircle.owner))
    })
  },
  computed: {
    ...mapState(['selectedCircle'])
  },
  methods: {
    acknowledgeReport(report) {
      this.axios.put(`reports/${report.id}`).then(() => {
        report.acknowledged = true
      })
    },
    viewUser(user) {
      this.userDetailsUsername = user.username
      this.userDetailsDialog = true
    }
  }
}
</script>

<style scoped>

</style>