<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Circles
            <v-spacer></v-spacer>
            <export-excel
                :data="circlesExport"
                name="circles.xls"
            >
              <v-btn small>Export to Excel</v-btn>
            </export-excel>
          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="all_circles"
              :search="search"
              :loading="loading"
              :items-per-page="15"
          >
            <template v-slot:top>
              <v-container fluid>
                <v-row>
                  <v-col>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-select
                        :items="[{text: 'Filter by commercial', value: null}, {text: 'Commercial', value: true}, {text: 'Non-commercial', value: false}]"
                        placeholder="Commercial" v-model="commercialSearch">
                    </v-select>
                  </v-col>
                  <v-col>
                    <v-select
                        :items="[{text: 'Filter by type', value: null}, {text: 'Public', value: 'public'}, {text: 'Private', value:'private'}]"
                        placeholder="Type" v-model="typeSearch">
                    </v-select>
                  </v-col>
                  <v-col>
                    <v-menu
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="createdRangeText"
                            placeholder="Creation Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="createdSearch"
                          range
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </template>
            <template v-slot:item.country="{ item }">
              <country-flag :country="item.metadata.country" v-if="item.metadata && item.metadata.country"/>
            </template>
            <template v-slot:item.rating="{ item }">
              <v-rating
                  :value="item.rating"
                  background-color="orange lighten-3"
                  color="orange"
                  half-increments
                  readonly
                  dense
                  small
              ></v-rating>
            </template>
            <template v-slot:item.createdAt="{ item }">
              {{ formatTimestamp(item.createdAt) }}
            </template>
            <template v-slot:item.reports="{ item }">
              {{ item.reports.length }}
            </template>
            <template v-slot:item.membersCount="{ item }">
              {{ item.membersCount }}
            </template>
            <template v-slot:item.metadata.isCommercial="{ item }">
              <strong v-if="item.metadata && item.metadata.isCommercial" class="success--text">Yes</strong>
              <strong v-else>No</strong>
            </template>
            <template v-slot:item.type="{ item }">
              <span class="text-capitalize">{{ item.type }}</span>
            </template>
            <template v-slot:item.metadata.isActive="{ item }">
              <strong v-if="item.metadata && item.metadata.isActive === true" class="success--text">Yes</strong>
              <strong v-else>No</strong>
            </template>
            <template v-slot:item.actions="{ item }">
              <td class="text-center">
                <v-btn icon color="default" @click="viewCircle(item)">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </td>
              <td class="text-center">
                <v-btn icon color="error" @click="deactivateCircle(item.guid)" v-if="item.metadata && item.metadata.isActive">
                  <v-icon>mdi-account-cancel</v-icon>
                </v-btn>
                <v-btn icon color="success" @click="activateCircle(item.guid)" v-else>
                  <v-icon>mdi-account-check</v-icon>
                </v-btn>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <confirm-dialog ref="confirm"></confirm-dialog>
  </v-container>
</template>

<script>
import {mapState} from "vuex";
import dateFormat from '../mixins/date';
import CountryFlag from 'vue-country-flag';
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: "Circles",
  mixins: [dateFormat],
  components: {
    CountryFlag,
    ConfirmDialog
  },
  data: () => ({
    search: '',
    typeSearch: null,
    commercialSearch: null,
    createdSearch: null,
    userDetailsDialog: false,
    userDetailsUsername: null,
    userListDialog: false,
    userList: [],
    userListCircle: null,
    userListLoading: false,
    reportsDialog: false,
    reports: [],
    reportsCircle: null,
    loading: false,
    dialog: false,
    all_circles: []
  }),
  async mounted() {
    this.loading = true
    this.fetchCircles()
    this.loading = false
  },
  computed: {
    ...mapState(['circles']),
    headers() {
      return [
        {text: 'Name', value: 'name'},
        {text: 'Type', value: 'type', filter: this.filterType},
        {text: 'Country', value: 'country'},
        {text: 'Rating', value: 'rating', filterable: false},
        {text: 'Users', value: 'membersCount', filterable: false},
        {text: 'Commercial', value: 'metadata.isCommercial', filter: this.filterCommercial},
        {text: 'Reports', value: 'reports', filterable: false},
        {text: 'Created', value: 'createdAt', filter: this.filterCreated},
        {text: 'Active', value: 'metadata.isActive', filterable: false, sortable: false},
        {text: 'Actions', value: 'actions', filterable: false, sortable: false},
      ]
    },
    createdRangeText: {
      get() {
        if (!this.createdSearch) {
          return ''
        }
        const dates = []
        this.createdSearch.forEach(date => {
          dates.push(this.formatDate(date))
        })
        return dates.join(' - ')
      },
      set() {
        this.createdSearch = null
      }
    },
    circlesExport() {
      const circlesExport = []
      this.all_circles.forEach(circle => {
        circlesExport.push({
          'Name': circle.name,
          'Description': circle.description,
          'Type': circle.type,
          'Members': circle.membersCount,
          'Created at': this.formatTimestamp(circle.createdAt),
          'Commercial': circle.metadata && circle.metadata.isCommercial ? 'Yes' : 'No',
          'Country': circle.metadata ? circle.metadata.country : circle.metadata,
          'Reports': circle.reports.length,
          'Rating': circle.rating,
          'Active': circle.metadata && circle.metadata.isActive ? 'Yes' : 'No',
        })
      })

      return circlesExport
    }
  },
  methods: {
    fetchCircles() {
      this.$axios.get('/circles').then(resp => {
        this.all_circles = resp.data
      })
    },
    filterType(value) {
      if (!this.typeSearch) {
        return true;
      }

      return value === this.typeSearch
    },
    filterCreated(value) {
      if (!this.createdSearch) {
        return true
      }

      return this.isTimestampInDateRange(value, this.createdSearch[0], this.createdSearch[1])
    },
    filterCommercial(value) {
      if (!this.commercialSearch) {
        return true
      }

      return this.commercialSearch === value
    },
    viewCircle(circle) {
      this.$store.commit('selectCircle', circle)
      this.$router.push('/circles/' + circle.guid)
    },
    async activateCircle(guid) {
      if (
          await this.$refs.confirm.open(
              "Confirm",
              "Are you sure you want to activate this circle?"
          )
      ) {
        this.$axios.put(`/circles/${guid}/activate`).then(() => {
          this.fetchCircles()
        })
      }
    },
    async deactivateCircle(guid) {
      if (
          await this.$refs.confirm.open(
              "Confirm",
              "Are you sure you want to deactivate this circle?"
          )
      ) {
        this.$axios.put(`/circles/${guid}/deactivate`).then(() => {
          this.fetchCircles()
        })
      }
    }
  }
}
</script>

<style scoped>

</style>