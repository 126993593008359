<template>
  <div>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="600"
    >
      <v-card>
        <v-card-title class="headline">
          Reports for {{ circle }}
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  Username
                </th>
                <th class="text-left">
                  Report Date
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="report in reports"
                  :key="report.id"
              >
                <td>{{ report.user.username }}</td>
                <td>{{ formatDateTime(report.report_date) }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="$emit('close')"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dateUtils from '../mixins/date'

export default {
  name: "CircleReports",
  mixins: [dateUtils],
  props: {
    dialog: {
      value: false
    },
    circle: {
      value: null
    },
    reports: {
      value: []
    },
    loading: {
      value: false
    }
  }
}
</script>

<style scoped lang="scss">
</style>