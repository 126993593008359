<template>
  <div>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="600"
    >
      <v-card :loading="loading">
        <v-card-title class="headline">
          Users in {{ circle }}
        </v-card-title>
        <v-card-text>
          <v-list v-if="!loading">
            <v-list-item v-for="user in users" :key="user.username">
              <a href="#" @click.prevent="showUserDetails(user.username)">
                {{user.username}}
              </a>
            </v-list-item>
          </v-list>
          <p v-if="loading">Loading...</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="$emit('close')"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <UserDetails :dialog="userDetailsDialog" :username="userDetailsUsername" @close="userDetailsDialog = false"/>
  </div>
</template>

<script>
import dateUtils from '../mixins/date'
import UserDetails from "@/components/UserDetails";

export default {
  name: "UserList",
  mixins: [dateUtils],
  components: {
    UserDetails
  },
  props: {
    dialog: {
      value: false
    },
    circle: {
      value: null
    },
    users: {
      value: []
    },
    loading: {
      value: false
    }
  },
  data: () => ({
    userDetailsDialog: false,
    userDetailsUsername: null
  }),
  methods: {
    showUserDetails(username) {
      this.userDetailsUsername = username
      this.userDetailsDialog = true
    }
  }
}
</script>

<style scoped lang="scss">
</style>